import PropTypes from "prop-types"
import React from "react"

import "./container.scss"

const Container = ({ children, ...rest }) => (
  <div className="container" {...rest}>
    {children}
  </div>
)

Container.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Container
