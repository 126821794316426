import React from "react"
import PropTypes from "prop-types"

import UpdateReady from "./update-ready"
import NavBar from "./nav-bar"
import Main from "./main"
import Footer from "./footer"

const Layout = ({ children, hideNav }) => (
  <React.Fragment>
    <UpdateReady />
    <NavBar hideNav={hideNav} />
    <Main>{children}</Main>
    <Footer>
      <p
        className="fg-black-3"
        style={{
          textAlign: `center`,
          fontSize: `13px`,
          fontFamily: `"Trocchi", serif`,
          fontWeight: 400,
        }}
      >
        © 2019 Lavinia Knop, Bjarke Walling.
      </p>
    </Footer>
  </React.Fragment>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hideNav: PropTypes.bool,
}

export default Layout
