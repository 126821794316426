import { createStorage } from "./storage"

const noop = () => {}

const pages = {
  toc: createStorage(`page_toc`, null),
  prolog: createStorage(`page_prolog`, null),
  chapters: createStorage(`page_chapters`, null),
  epilog: createStorage(`page_epilog`, null),
}

export const getContentFor = id =>
  pages[id] || {
    get: noop,
    set: noop,
    on: noop,
    off: noop,
  }

export const clearAllContent = () =>
  Object.keys(pages).forEach(id => pages[id].clear())

export const getRevisionFor = id =>
  Number((getContentFor(id).get() || {}).rev) || 0

export const getRevisionData = () =>
  Object.keys(pages)
    .map(id => `${id}=${getRevisionFor(id)}`)
    .join("&")
