let returnURL = null

export const setReturnURL = url => {
  returnURL = url
}

export const getReturnURL = () => {
  let url = returnURL || `/site/toc/`
  returnURL = null
  return url
}
