import React, { useState } from "react"
import { navigate } from "gatsby"
import { emailStorage } from "../utils/storage"
import { focusDOM } from "../utils/dom"
import { getReturnURL } from "../utils/login"

import { isLoggedIn, getCurrentUser, signin, signout } from "../api/auth"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./login.scss"

const LoginPage = () => {
  const [email, setEmail] = useState(emailStorage.get())
  const [password, setPassword] = useState("")
  const [isLoading, setLoading] = useState(false)
  const [isSubmittedOnce, setSubmittedOnce] = useState(false)
  const [serverResponse, setServerResponse] = useState("")

  const validation = []
  if (!email.trim()) {
    validation.push(`Please enter your email.`)
  }
  if (!/@/.test(email)) {
    validation.push(`Please check your email.`)
  }
  if (!password) {
    validation.push(`Please enter your password.`)
  }

  const errors = validation.slice(0)
  if (!isLoading && serverResponse) {
    errors.unshift(serverResponse)
  }

  return (
    <Layout>
      <SEO lang="en" title="Login" />
      <form
        className="login--container"
        name="login"
        onSubmit={async e => {
          e.preventDefault()
          setSubmittedOnce(true)
          if (isLoading) return

          if (validation.length > 0) return focusDOM("login-errors")

          emailStorage.set(email)
          setLoading(true)
          let ok = false

          try {
            ok = await signin(email.trim(), password)
          } catch (error) {}
          setLoading(false)

          if (!ok) {
            setServerResponse(
              `There was an error trying to login, please try again.`
            )
            focusDOM("login-errors")
            return
          }

          setPassword("")
          setSubmittedOnce(false)
          navigate(getReturnURL(), { replace: true })
        }}
      >
        <h1 className="fg-black-5">Login</h1>
        {isLoggedIn() ? (
          <p className="login--already-logged-in fg-black-3">
            You're logged in as {getCurrentUser().name}.{" "}
            <button
              className="fg-blue-0"
              onClick={e => {
                e.preventDefault()
                signout()
                setPassword("")
                setSubmittedOnce(false)
                focusDOM("email", { select: true })
                navigate(`/login/`)
              }}
            >
              Log in as another user
            </button>
          </p>
        ) : (
          <section>
            <p className="fg-black-5">
              <label className="login--input-label" htmlFor="email">
                Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                className="fg-black-3 bb-black-3"
                placeholder="you@example.com"
                required
                disabled={isLoading}
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </p>
            <p className="fg-black-5">
              <label className="login--input-label" htmlFor="password">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                className="fg-black-3 bb-black-3"
                placeholder="your secret"
                required
                disabled={isLoading}
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </p>
            <p className="login--submit-area">
              <input
                className="login--submit-button bg-white-0 fg-black-3 ba-black-3"
                type="submit"
                disabled={isLoading}
                value="Login"
              />
            </p>
            <div
              className="login--submit-errors fg-red-0"
              role="alert"
              id="login-errors"
              tabIndex="-1"
            >
              {isSubmittedOnce && errors.length > 0 ? (
                <ul>
                  {errors.map((message, index) => (
                    <li key={index}>{message}</li>
                  ))}
                </ul>
              ) : null}
            </div>
            <div className="login--note">
              <h2 className="fg-black-6">How do I get a password?</h2>
              <p className="fg-black-5">
                If you didn’t register yet, please open your invitation email
                and click on the red button to create a user on the website.
              </p>
              <p className="fg-black-5">
                If have problems or any questions for us, please do not hesitate
                to get in contact.
              </p>
            </div>
          </section>
        )}
      </form>
    </Layout>
  )
}

export default LoginPage
