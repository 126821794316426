import React from "react"
import PropTypes from "prop-types"

import Container from "./container"

const Footer = ({ children }) => (
  <footer
    className="bg-white-3-off fg-black-3 bt-white-4"
    style={{
      padding: `40px 0`,
      borderTopWidth: `1px`,
      borderTopStyle: `solid`,
    }}
  >
    <Container>{children}</Container>
  </footer>
)

Footer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Footer
