import React from "react"
import PropTypes from "prop-types"

import Container from "./container"

const Main = ({ children }) => (
  <main className="bg-white-0-off">
    <Container>{children}</Container>
  </main>
)

Main.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Main
