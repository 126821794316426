import axios from "./axios"
import { getTime } from "./util"
import { userStorage } from "../utils/storage"
import {
  getContentFor,
  clearAllContent,
  getRevisionData,
} from "../utils/content"

const isBrowser = typeof window !== `undefined`

const setUser = data => {
  const pages = (data || {}).pages
  if (pages && typeof pages === "object") {
    Object.keys(pages).forEach(id => getContentFor(id).set(pages[id]))
  }

  const user = (data || {}).user || undefined
  userStorage.set(user)
  return user
}

export const getCurrentUser = () => (isBrowser && userStorage.get()) || null
export const isLoggedIn = () => !!(getCurrentUser() || {}).email

export const getStatus = async () => {
  const { data } = await axios.get(
    `auth/status?t=${getTime()}&${getRevisionData()}`
  )
  return setUser(data)
}

export const signin = async (email, password) => {
  const { data } = await axios.post(`auth/signin?t=${getTime()}`, {
    email,
    password,
  })
  return !!(setUser(data) || {}).email
}

export const signout = async () => {
  setUser(null)
  clearAllContent()
  await axios.post(`auth/signout?t=${getTime()}`)
  return true
}

const updateStatus = async () => {
  try {
    getStatus()
  } catch (error) {}
}

updateStatus()
