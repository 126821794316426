const delay = wait => new Promise(resolve => setTimeout(resolve, wait))

export const getTime = () =>
  `${new Date().getTime()}${`${Math.random()}`.slice(1)}`

export const slowDown = (fn, minimumWait = 300) => async data => {
  const startTime = new Date()
  const result = await fn(data)
  const remainingTime = minimumWait - (new Date() - startTime)
  if (remainingTime > 10) {
    await delay(remainingTime)
  }
  return result
}
