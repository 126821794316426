import React, { useState, useEffect } from "react"

import Container from "./container"
import { isReady, addListener, removeListener, reload } from "../utils/offline"

const UpdateReady = () => {
  const [updateReady, setUpdateReady] = useState(isReady())

  useEffect(() => {
    const listener = () => setUpdateReady(isReady())
    addListener(listener)
    return () => removeListener(listener)
  })

  return updateReady ? (
    <div style={{ backgroundColor: `#cef` }}>
      <Container>
        <p>
          <strong>Notice:</strong> This application has been updated.
          <button onClick={reload}>
            Click here to display the latest version
          </button>
        </p>
      </Container>
    </div>
  ) : null
}

export default UpdateReady
